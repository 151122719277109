import { APIClient, HttpClient } from "@/utils/httpClient";
import { defineStore } from "pinia";
import router from "@/router";
import { campaignStore } from "./campaignStore";
import { navigationStore } from "./navigationStore";
import { pendingEventStore } from "./pendingEventStore";

interface IAuthStoreState {
	campaignID: string | null;
	email: string | null;
	userID: string | null;
	walletID: string | null;
	refreshToken: string | null;
	otc: string | null;
	pageAfterAuth: boolean;
	userUUID: string | null;
	logging: boolean | null;
}

export const authStore = defineStore("authStore", {
	persist: true,
	state: () =>
		({
			campaignID: null,
			email: null,
			userID: null,
			walletID: null,
			otc: null,
			pageAfterAuth: false,
			userUUID: null,
			logging: true,
		} as IAuthStoreState),
	getters: {
		getCampaignID(state: IAuthStoreState): string | null {
			return state.campaignID;
		},
		getEmail(state: IAuthStoreState): string | null {
			return state.email ? state.email.toLowerCase() : null;
		},
		getWalletID(state: IAuthStoreState): string | null {
			return state.walletID;
		},
		getUserID(state: IAuthStoreState): string | null {
			return state.userID;
		},
		getRefreshToken(state: IAuthStoreState): string | null {
			return state.refreshToken;
		},
		getPageAfterAuth(state: IAuthStoreState): boolean {
			return state.pageAfterAuth;
		},
		getOtc(state: IAuthStoreState): string | null {
			return state.otc;
		},
		getUserUUID(state: IAuthStoreState): string | null {
			return state.userUUID;
		},
		getLogging(state: IAuthStoreState): boolean | null {
			return state.logging;
		},
	},
	actions: {
		saveOTC(otc: string) {
			this.otc = otc;
		},
		saveCampaignID(campaignID: string) {
			this.campaignID = campaignID;
		},
		saveEmail(email: string) {
			this.email = email;
		},
		saveRefreshToken(token: string) {
			this.refreshToken = token;
		},
		saveWalletID(walletID: string) {
			this.walletID = walletID;
		},
		saveUserID(userID: string) {
			this.userID = userID;
		},
		savePageAfterAuth(pageAfterAuth: boolean) {
			this.pageAfterAuth = pageAfterAuth;
		},
		saveUserUUID(userUUID: string) {
			this.userUUID = userUUID;
		},
		logout() {
			router.push(`/${this.campaignID}`).catch((error) => {
				console.error(JSON.stringify(error, null, 2));
			});
			this.email = null;
			this.userID = null;
			this.walletID = null;
			localStorage.clear();
		},
		saveStopLogging() {
			this.logging = false;
			const loggingInterval = setInterval(() => {
				this.logging = true;
				clearInterval(loggingInterval);
			}, 1800000);
		},
		async dispatchGetCampaignID(subDomain: string) {
			try {
				const response = await APIClient.get(
					`/campaigns/subdomain/${subDomain}`
				);
				if (response.success) {
					this.campaignID = response.data.id;
					return this.campaignID;
				} else {
					if (response.data.status == "CAMPAIGN-ENDED") {
						router.replace(`/${this.campaignID}/campaign-over`).catch(() => {
							//
						});
					}
				}
			} catch (error) {
				console.error(error);
				return;
			}
		},
		async registerLoginCode(obj: any): Promise<boolean> {
			const endpoint = `/campaigns/${this.campaignID}/users/register`;
			const resendPin = document.getElementById("resend-pin");
			let originalResendText = "";
			if (resendPin) {
				originalResendText = resendPin!.innerText;
				resendPin!.innerText = "Sending new code";
			}

			obj.email = obj.email.toLowerCase();
			obj.email = obj.email.trim();
			try {
				obj.identifier = this.userUUID;
			} catch (error) {
				console.error("error getting userUUID");
			}

			try {
				const response = await APIClient.post(endpoint, obj);

				if (response.success) {
					this.saveEmail(obj.email);
					this.saveWalletID(response.data.walletID);
					this.saveUserID(response.data.userID);
					if (resendPin) {
						setTimeout(() => {
							resendPin!.innerText = originalResendText;
						}, 1000);
					}
					return true;
				} else {
					if (response.data.status == "USER-REGISTERED") {
						await this.signinLoginCode({
							email: obj.email,
						});
					}
					if (response.data.status == "CAMPAIGN-ENDED") {
						router.replace(`/${this.campaignID}/campaign-over`).catch(() => {
							//
						});
					}
					return false;
				}
			} catch (error: any) {
				console.error(JSON.stringify(error, null, 2));
				if (error.response?.data.message == "email blocked") {
					navigationStore().emitAction({
						type: "open-popup",
						value: "email-blocked",
					});
					return false;
				}
				alert("An error occured, please try again");
				console.error(error);
				return false;
			}
		},
		async signinLoginCode(obj: any): Promise<boolean> {
			const endpoint = `/campaigns/${this.campaignID}/users/login/request`;
			const resendPin = document.getElementById("resend-pin");
			let originalResendText = "";
			if (resendPin && resendPin.innerText) {
				originalResendText = resendPin!.innerText;
				resendPin!.innerText = "Sending new code";
				obj.retry = true;
			}

			obj.email = obj.email.toLowerCase();
			obj.email = obj.email.trim();

			try {
				const response = await APIClient.post(endpoint, obj);

				if (response.success) {
					this.saveEmail(obj.email);
					this.saveWalletID(response.data.walletID);
					this.saveUserID(response.data.userID);
					if (resendPin && resendPin.innerText) {
						setTimeout(() => {
							resendPin!.innerText = originalResendText;
						}, 1000);
					}
					router.replace(`/${this.campaignID}/enter-pin`).catch(() => {
						//
					});
					return true;
				} else {
					// TODO: Alert something failed
					if (response.data.status == "USER-NOT-REGISTERED") {
						alert(
							"You are not registered. Redirecting to to the registration page."
						);
						router.replace(`/${this.campaignID}`).catch(() => {
							//
						});
					}
					if (response.data.status == "CAMPAIGN-ENDED") {
						router.replace(`/${this.campaignID}/campaign-over`);
					}

					return false;
				}
			} catch (error) {
				console.error(error);
				alert("An error occured while signing in, please try again.");
				return false;
			}
		},
		async login(code: number) {
			const endpoint = `/campaigns/${this.campaignID}/users/login`;
			try {
				const response = await APIClient.post(endpoint, {
					email: this.email,
					code,
				});
				if (response.success) {
					localStorage.setItem("AccessToken", response.data.accessToken);
					localStorage.setItem("RefreshToken", response.data.refreshToken);
					this.saveWalletID(response.data.walletID);
					pendingEventStore().startPendingEvents();
					return true;
					// TODO: redirect to wallet page
				} else {
					// TODO: Alert something failed
					if (response.data.status == "CAMPAIGN-ENDED") {
						router.replace(`/${this.campaignID}/campaign-over`).catch(() => {
							//
						});
					}
					return false;
				}
			} catch (error) {
				console.error(error);
				return false;
			}
		},
		async checkAuthenticated() {
			const token = localStorage.getItem("RefreshToken");
			const accessToken = localStorage.getItem("AccessToken");

			if (token && !HttpClient.checkExpired(accessToken as string)) {
				try {
					const response = await APIClient.refreshToken(token);

					const campaign = campaignStore().campaign;
					// const campaign: Campaign = store.getters["campaignStore/getItem"];

					if (response.success) {
						const prevWalletID = authStore().getWalletID;
						console.log("prevWalletID");
						console.log(prevWalletID);
						console.log("walletID");
						console.log(response.data.walletID);
						if (
							prevWalletID != null &&
							prevWalletID != "" &&
							prevWalletID != response.data.walletID
						) {
							authStore().savePageAfterAuth(false);
						}

						let pageAfterAuth = campaign?.pageAfterAuth;
						console.log(authStore().getPageAfterAuth);
						if (
							(!pageAfterAuth || pageAfterAuth == "") &&
							!this.pageAfterAuth
						) {
							pageAfterAuth = "vault";
						}
						this.saveWalletID(response.data.walletID);

						if (!this.pageAfterAuth) {
							this.savePageAfterAuth(true);
							router
								.push({
									path: `/${this.campaignID}/${pageAfterAuth}`,
								})
								.catch(() => {
									//
								});
						} else if (
							router.currentRoute.value.name == "Register" ||
							router.currentRoute.value.name == "SignIn" ||
							router.currentRoute.value.name == "Enter Pin"
						) {
							this.savePageAfterAuth(true);
							router
								.push({
									path: `/${this.campaignID}/vault`,
								})
								.catch(() => {
									//
								});
						}
					} else {
						if (response.data.status == "CAMPAIGN-ENDED") {
							router.replace(`/${this.campaignID}/campaign-over`).catch(() => {
								//
							});
						}
					}
				} catch (error) {
					console.error(error);
					return false;
				}
			}
		},
		async autoRegister(autoReg: { email: string; queryString: string }) {
			const endpoint = `/campaigns/${this.campaignID}/users/autoregister?${autoReg.queryString}`;

			try {
				const response = await APIClient.get(endpoint);
				console.log(response);
				if (response.success) {
					this.saveEmail(autoReg.email);
					this.saveWalletID(response.data.walletID);
					this.saveUserID(response.data.userID);
					router.replace(`/${this.campaignID}/enter-pin`).catch(() => {
						//
					});
				} else {
					if (response.data.status == "USER-REGISTERED") {
						alert("You have already registered, redirecting to sign in page");
						router.replace(`/${this.campaignID}/sign-in`).catch(() => {
							//
						});
					}
					// TODO: Alert something failed
					if (response.data.status == "CAMPAIGN-ENDED") {
						router.replace(`/${this.campaignID}/campaign-over`).catch(() => {
							//
						});
					}
					return false;
				}
			} catch (error) {
				console.error(error);
				return false;
			}
		},
		async dispatchUserEvent(event: {
			campaignID: string;
			event: string;
			identifier: string;
			pageRoute: string;
		}) {
			const endpoint = `/events`;
			const campaignID = this.campaignID;
			event.campaignID = campaignID!;
			try {
				const response = await APIClient.post(endpoint, event);
				if (response.success) {
					return true;
				} else {
					return false;
				}
			} catch (error) {
				console.error(error);
				return false;
			}
		},
	},
});

import { navigationStore } from "@/store/pinia_modules/navigationStore";
import { vItemStore } from "@/store/pinia_modules/vItemStore";
import { APIClient } from "@/utils/httpClient";

export const generateShare = async (
	campaignID: string,
	userID: string,
	dropPoolID: string | null
): Promise<string | null> => {
	try {
		const endpoint = `/campaigns/${campaignID}/share`;
		const response = await APIClient.post(endpoint, { userID, dropPoolID });
		if (response.success) {
			if (response.data.updateWallet) {
				await vItemStore().getItems();
			}
			return response.data.url;
		}
	} catch (error) {
		console.error(error);
	}
	return null;
};

export const generateShareReward = async (
	campaignID: string,
	userID: string,
	vatID: string | null
): Promise<string | null> => {
	navigationStore().setloading(true);
	try {
		const endpoint = `/campaigns/${campaignID}/vitem/share`;
		const response = await APIClient.post(endpoint, {
			userID: userID,
			vitemID: vatID,
		});
		if (response.success) {
			await vItemStore().getItems();
			navigationStore().setloading(false);
			return response.data.shareID;
		}
	} catch (error) {
		navigationStore().setloading(false);
		console.error(error);
	}
	return null;
};

export const cancelShareReward = async (
	campaignID: string,
	shareID: string
): Promise<string | null> => {
	navigationStore().setloading(true);
	try {
		const endpoint = `/campaigns/${campaignID}/vitem/share/${shareID}/cancel`;
		const response = await APIClient.post(endpoint, {});
		if (response.success) {
			await vItemStore().getItems();
			navigationStore().setloading(false);
			return response.data.shareID;
		}
	} catch (error) {
		navigationStore().setloading(false);
		console.error(error);
	}

	return null;
};

<template>
	<div id="app">
		<router-view />
		<div class="popups" v-if="popupVisible && selectedPopup">
			<ContainerObject :containerObject="selectedPopup" />
		</div>
		<LoadingComponent v-if="loading" />
	</div>
</template>
<script lang="ts">
import ContainerObject from "@/components/layout/ContainerObject.vue";
import LoadingComponent from "@/components/utils/LoadingComponent.vue";
import { Campaign } from "@/types/campaign";
import { parse } from "tldts";
import router from "./router/index";

import { Container } from "./types/customPage";
import { v4 as uuidv4 } from "uuid";

import { defineComponent } from "vue";
import { customPageStore } from "./store/pinia_modules/customPageStore";
import { vItemStore } from "./store/pinia_modules/vItemStore";
import { dropPoolStore } from "./store/pinia_modules/dropPoolStore";
import { poolSegmentStore } from "./store/pinia_modules/poolSegmentStore";
import { navigationStore } from "./store/pinia_modules/navigationStore";
import { authStore } from "./store/pinia_modules/authStore";
import { campaignStore } from "./store/pinia_modules/campaignStore";
import { customComponentStore } from "./store/pinia_modules/customComponentStore";
import {
	PendingEventType,
	pendingEventStore,
} from "./store/pinia_modules/pendingEventStore";
import { categoryStore } from "./store/pinia_modules/categoryStore";
import { prizeStore } from "./store/pinia_modules/prizeStore";
import moment from "moment";
import { jwtDecode } from "jwt-decode";

export default defineComponent({
	components: {
		ContainerObject,
		LoadingComponent,
	},
	data() {
		return {
			subDomain: null as string | null,
			campaignID: null as string | null ,
			path: null as string | null,
			email: null as string | null,
			otc: null as string | null,
			shareCode: null as string | null,
			shareRewardCode: null as string | null,
			errorMessage: "",
			shortMessage: "",
			campaignEnded: false,
			notForDesktop: false,
		};
	},
	mounted() {
		this.init();
	},
	methods: {
		async init() {
			try {
				navigationStore().loading = true;

				//Clear local storage
				customPageStore().clearItems();
				vItemStore().clearItems();
				dropPoolStore().clearItems();
				poolSegmentStore().clearItems();

				//Create user UUID if it doesn't exist
				this.createUserUUID();

				//Get all required variables
				this.getSubdomain();
				await this.getPath();

				let campaignIdentifier: {
					subdomain: string | null;
					campaignID: string | null;
				} = { subdomain: null, campaignID: null };

				if (this.campaignID) {
					campaignIdentifier.campaignID = this.campaignID;
				} else if (this.subDomain) {
					campaignIdentifier.subdomain = this.subDomain;
				}

				await this.getUIElements(campaignIdentifier);

				if (this.campaign) {
					if (this.campaign.paused) {
						navigationStore().loading = false;
						router.push(`/${this.campaignID}/campaign-paused`);
						return;
					}
				}

				this.getQueryParams();

				//Submit user UUID if campaign ID is valid
				this.submitUserUUID();

				if (this.campaignEnded) {
					navigationStore().loading = false;
					navigationStore().emitAction({
						type: "go-to-page",
						value: "campaign-over",
					});

					return;
				}

				router.push(`/${this.campaignID}`);

				await Promise.all([
					this.checkIfShareCodeExists(),
					this.checkIfShareRewardCodeExists(),
					this.checkAutoLogin(),
					this.checkAutoReg(),
				]);

				this.startPendingEvents();
				this.checkAuthenticated();
				navigationStore().loading = false;
			} catch (err) {
				console.error(err);
				if (err instanceof Error) {
					this.errorMessage = err.message;
					this.shortMessage = "An application error has occurred";
					return this.showErrorPage();
				} else {
					this.errorMessage = "Unknown error";
					this.shortMessage = "An application error has occurred";
					return this.showErrorPage();
				}
			}
		},
		createUserUUID() {
			console.log(this.userUUID);
			if (!this.userUUID) {
				const uuid = uuidv4();
				console.log(uuid);
				authStore().saveUserUUID(uuid);
			}
		},
		submitUserUUID() {
			let uuid = "";
			if (this.userUUID) {
				uuid = this.userUUID;
			}
			authStore().dispatchUserEvent({
				campaignID: this.campaignID!,
				event: "landing-page-view",
				identifier: uuid,
				pageRoute: window.location.pathname,
			});
		},
		showErrorPage() {
			navigationStore().loading = false;
			router.push(
				`/error?message=${this.errorMessage}&shortMessage=${this.shortMessage}`
			);
		},
		getSubdomain() {
			this.subDomain = parse(window.location.hostname).subdomain;
		},
		async getPath() {
			this.path = window.location.pathname;

			if (this.path[0] == "/") {
				this.path = this.path.substring(1);
			}
			if (this.path[this.path.length - 1] == "/") {
				this.path = this.path.slice(0, -1);
			}
			const split = this.path.split("/");
			if (
				split.length == 1 &&
				split[0] != "" &&
				split[0] != "autoreg" &&
				split[0] != "enter-pin" &&
				split[0] != "autologin"
			) {
				console.log(split[0]);
				const resp = await campaignStore().getCampaignFromPath(split[0]);
				if (resp.success) {
					this.campaignID = resp.data.id;
				} else {
					this.campaignID = split[0];
				}
				authStore().saveCampaignID(this.campaignID!);
			}
			if (split.length == 2) {
				this.path = split[1];
				this.campaignID = split[0];
				authStore().saveCampaignID(this.campaignID);
			}
		},
		getQueryParams() {
			const params = new URL(document.location.toString()).searchParams;
			params.forEach((v, k) => {
				params.set(k, encodeURI(v));
			});
			if (params.get("email")) {
				this.email = params.get("email");
				authStore().saveEmail(
					decodeURIComponent(params.get("email") as string)
				);
			}
			if (params.get("otc")) {
				this.otc = params.get("otc");
				authStore().saveOTC(this.otc!);
			}
			if (params.get("shareCode")) {
				this.shareCode = params.get("shareCode");
			}
			if (params.get("shareRewardCode")) {
				this.shareRewardCode = params.get("shareRewardCode");
			}
			if (params.get("campaign")) {
				this.campaignID = params.get("campaign");
			}
			if (params.get("token") && params.get("walletID")) {
				const decoded: any = jwtDecode(params.get("token")!);
				localStorage.setItem("AccessToken", params.get("token")!);
				authStore().saveWalletID(params.get("walletID")!);
				if (decoded && decoded.username) {
					authStore().saveUserID(decoded.username);
				}
			}
		},

		async getUIElements(campaignIdentifier: {
			subdomain: string | null;
			campaignID: string | null;
		}): Promise<boolean> {
			let initialCampaignData;
			try {
				initialCampaignData = await campaignStore().getInitialCampaignData(
					campaignIdentifier
				);
			} catch (err) {
				console.error(err);
				this.errorMessage = "Web App not found";
				this.shortMessage = "Web App not found";
				return false;
			}

			this.campaignID = initialCampaignData.campaign.id;
			campaignStore().setCampaign(initialCampaignData.campaign);
			customPageStore().saveItems(initialCampaignData.customPages);
			customComponentStore().saveItems(initialCampaignData.customComponents);
			categoryStore().addItems(initialCampaignData.categories);
			dropPoolStore().saveItems(initialCampaignData.dropPools);
			prizeStore().saveItems(initialCampaignData.prizes);

			const campaign = campaignStore().getItem;
			if (campaign == null) {
				this.errorMessage = "Web App not found";
				this.shortMessage = "Web App not found";
				return false;
			} else {
				authStore().saveCampaignID(campaign.id!);
				if (campaign.pageTitle && campaign.pageTitle != "") {
					document.title = campaign.pageTitle;
					this.setMeta(campaign);
				} else {
					document.title = "Inner Reality";
					this.setMeta(campaign);
				}
				if (campaign.favicon) {
					this.setFavicons(campaign.favicon);
				}
			}
			const today = moment().format("YYYY-MM-DD");
			if (moment(campaign.endDate).isBefore(moment(today))) {
				this.campaignEnded = true;
				return false;
			}
			this.addCustomFont();
			return true;
		},
		setMeta(campaign: Campaign) {
			const siteName = document.createElement("meta");
			siteName.setAttribute("property", "og:site_name");
			siteName.setAttribute("content", campaign.pageTitle!);
			const siteTitle = document.createElement("meta");
			siteTitle.setAttribute("property", "og:title");
			siteTitle.setAttribute("content", campaign.pageTitle!);
			const siteDescription = document.createElement("meta");
			siteDescription.setAttribute("property", "og:description");
			siteDescription.setAttribute("content", campaign.description!);
			const siteImage = document.createElement("meta");
			siteImage.setAttribute("property", "og:image:secure_url");
			siteImage.setAttribute("itemprop", "image");
			siteImage.setAttribute("content", campaign.logo!);
			const type = document.createElement("meta");
			type.setAttribute("property", "og:type");
			type.setAttribute("content", "website");
			const time = document.createElement("meta");
			time.setAttribute("property", "og:updated_time");
			time.setAttribute("content", `${Date.now()}`);
			document.head.appendChild(siteName);
			document.head.appendChild(siteTitle);
			document.head.appendChild(siteDescription);
			document.head.appendChild(siteImage);
			document.head.appendChild(type);
			document.head.appendChild(time);
		},
		setFavicons(favImg: string) {
			// const faviconLink = document.createElement("link");
			// faviconLink.rel = "shortcut icon";
			// faviconLink.type = "image/x-icon";
			// faviconLink.href = favImg;
			// document.head.appendChild(faviconLink);

			let newFaviconLink = document.createElement("link");
			newFaviconLink.rel = "icon";
			newFaviconLink.href = favImg;

			var existingFavicon = document.querySelector('link[rel="icon"]');
			if (existingFavicon) {
				existingFavicon.parentNode!.removeChild(existingFavicon);
			}

			document.head.appendChild(newFaviconLink);
		},
		// async checkForDesktop() {
		// 	if (!this.isMobile()) {
		// 		router.push(`/${this.campaignID}/not-for-desktop`);
		// 	} else {
		// 		router.push(`/${this.campaignID}`);
		// 	}
		// },
		async checkIfShareCodeExists(): Promise<void> {
			if (this.shareCode) {
				// Add to pendingEventQueue
				await pendingEventStore().addEvent({
					event: PendingEventType.COLLECT_SHARE,
					value: this.shareCode,
					id: uuidv4(),
				});
				window.location.search = "";
			}
		},
		async checkIfShareRewardCodeExists(): Promise<void> {
			if (this.shareRewardCode) {
				// Add to pendingEventQueue
				await pendingEventStore().addEvent({
					event: PendingEventType.COLLECT_SHARE_REWARD,
					value: this.shareRewardCode,
					id: uuidv4(),
				});
				window.location.search = "";
			}
		},
		async checkAutoLogin() {
			console.log("this.path", this.path);
			if (this.path == "enter-pin" && this.otc && this.email) {
				try {
					this.loading = true;
					const result = await authStore().login(parseInt(this.otc!));
					this.loading = false;
					if (result) {
						const campaign = campaignStore().getItem;
						let pageAfterAuth = campaign?.pageAfterAuth;
						if (!pageAfterAuth || pageAfterAuth == "") {
							pageAfterAuth = "vault";
						}
						router.push(`/${this.campaignID}/${pageAfterAuth}`);
					} else {
						if (
							router.currentRoute.value.name !== "Register" &&
							router.currentRoute.value.name !== "SignIn" &&
							router.currentRoute.value.name !== "Enter Pin"
						) {
							if (router.currentRoute.value.name != undefined) {
								router.push(`/${this.campaignID}`);
							}
						}
					}
				} catch (error) {
					console.error(error);
				}
			}
			if (localStorage.getItem("AccessToken")) {
				const campaign = campaignStore().getItem;
				let pageAfterAuth = campaign?.pageAfterAuth;
				if (!pageAfterAuth || pageAfterAuth == "") {
					pageAfterAuth = "vault";
				}
				router.push(`/${this.campaignID}/${pageAfterAuth}`);
			}
		},
		async checkAutoReg() {
			if (this.path == "autoreg") {
				const params = new URL(document.location.toString()).searchParams;
				params.forEach((v, k) => {
					params.set(k, encodeURI(v));
				});
				await authStore().autoRegister({
					queryString: params.toString(),
					email: this.email!,
				});
			}
		},
		startPendingEvents() {
			if (this.checkTokens()) {
				pendingEventStore().startPendingEvents();
			}
		},
		async checkAuthenticated() {
			await authStore().checkAuthenticated();
		},
		checkTokens(): boolean {
			if (
				localStorage.getItem("AccessToken") &&
				localStorage.getItem("RefreshToken")
			) {
				return true;
			}
			return false;
		},

		isMobile(): boolean {
			let isMobile =
				/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
					navigator.userAgent
				)
					? true
					: false;
			const isDevMode = process.env.NODE_ENV == "development" ? true : false;
			if (isDevMode) {
				isMobile = true;
			}

			return isMobile;
		},

		addCustomFont(): void {
			const css = `${this.campaign!.googleFont}
		html{
			${
				this.campaign!.fontClass !== ""
					? this.campaign!.fontClass
					: "font-family: Arial, Helvetica, sans-serif;"
			}
		}
		input{
			${
				this.campaign!.fontClass !== ""
					? this.campaign!.fontClass
					: "font-family: Arial, Helvetica, sans-serif;"
			}
		}
		button{
			${
				this.campaign!.fontClass !== ""
					? this.campaign!.fontClass
					: "font-family: Arial, Helvetica, sans-serif;"
			}
		}

		`,
				head = document.head || document.getElementsByTagName("head")[0],
				style = document.createElement("style");

			head.appendChild(style);
			style.appendChild(document.createTextNode(css));
		},
	},
	computed: {
		campaign(): Campaign | null {
			return campaignStore().getItem;
		},
		popupVisible(): boolean {
			return navigationStore().getPopupVisible;
		},
		selectedPopup(): Container {
			return navigationStore().getSelectedPopup;
		},
		loading(): boolean {
			return navigationStore().getLoading;
		},
		userUUID(): string | null {
			return authStore().getUserUUID;
		},
	},
});
</script>

<style lang="scss">
html {
	box-sizing: border-box;
	height: 100%;
	width: 100%;
	overflow: hidden;
	// font-family: Arial, Helvetica, sans-serif;
}
*,
*:before,
*:after {
	box-sizing: inherit;
}
body {
	height: 100%;
	width: 100%;
	margin: 0;
	padding: 0;
	overflow: hidden;
}
#app {
	// display: block;
	overflow: hidden;
	width: 100%;
	height: 100%;
}
.popups {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 10000;
}

#splash {
	z-index: -1 !important;
	display: none !important;
}
</style>

<template>
	<div>
		<div v-if="getClaimedItems && getClaimedItems.length > 0" class="scroller">
			<div
				class="item-cell"
				v-for="(item, index) in getClaimedItems"
				:key="index"
			>
				<div
					@click="clickForPrize(item)"
					class="item-center vat vat-claimed"
					v-if="item.itemStatus == 'claimed'"
				>
					<ContainerObject
						:containerObject="getCategoryContainer(item.categoryID, 'claimed')"
					/>
				</div>

				<div
					@click="clickForRedemption(item)"
					class="item-center vat vat-allocated"
					v-if="item.itemStatus == 'allocated'"
				>
					<ContainerObject
						:class="{ 'grey-out': item?.shareStatus == 'pending' }"
						:id="item.id"
						:containerObject="getPrizeContainer(item.prizeID!, 'allocated')"
					/>
				</div>

				<div
					@click="showRedeemedPopup(item)"
					class="item-center vat vat-redeemed"
					v-if="item.itemStatus == 'redeemed'"
				>
					<ContainerObject
						:containerObject="getPrizeContainer(item.prizeID!, 'redeem')"
					/>
				</div>
			</div>
		</div>
		<div v-if="showFaceCard && selectedFaceCard" class="facecard-popup">
			<ContainerObject
				:containerObject="selectedFaceCard"
				@event="customEvent($event)"
			/>
		</div>
		<LoadingComponent v-if="loading" />
	</div>
</template>

<script lang="ts">
import { VItem } from "@/types/vItem";

import { Action, Container } from "@/types/customPage";
import LoadingComponent from "@/components/utils/LoadingComponent.vue";
import { defineComponent } from "vue";
import { emptyContainerWithMessage } from "@/utils/container";
import { navigationStore } from "@/store/pinia_modules/navigationStore";
import { vItemStore } from "@/store/pinia_modules/vItemStore";
import { authStore } from "@/store/pinia_modules/authStore";
import { prizeStore } from "@/store/pinia_modules/prizeStore";
import { dropPoolStore } from "@/store/pinia_modules/dropPoolStore";
import { categoryStore } from "@/store/pinia_modules/categoryStore";

export default defineComponent({
	components: {
		LoadingComponent,
	},
	data() {
		return {
			selectedFaceCard: null as Container | null,
			selectedItem: null as VItem | null,
			loyaltyVats: [] as VItem[],
		};
	},
	async mounted() {
		this.selectedItem = null;
		navigationStore().saveShowFaceCard(false);

		// this.getDeviceId();
	},
	computed: {
		loading(): boolean {
			return navigationStore().getLoading;
		},
		showFaceCard(): boolean {
			return navigationStore().getShowFaceCard;
		},
		getClaimedItems(): VItem[] {
			const items: VItem[] = [];
			const allItems = vItemStore().getClaimedItems;
			for (let i = 0; i < allItems.length; i++) {
				if (
					// this.getPrizeByID(allItems[i].prizeID!) &&
					this.getCategoryByID(allItems[i].categoryID)
				) {
					items.push(allItems[i]);
				}
			}
			return items;
		},

		action(): Action {
			return navigationStore().getAction;
		},
	},
	watch: {
		action(to: Action): void {
			if (to) {
				if (to.type == "show-scanner") {
					const campaignID = authStore().getCampaignID;
					this.$router.push(`/${campaignID}/scanner`).catch(() => {
						//
					});
				} else if (to.type == "go-to-page") {
					if (to.value == "vault") {
						navigationStore().saveShowFaceCard(false);
					}
				} else if (to.type == "show-facecard") {
					navigationStore().saveShowFaceCard(true);
					if (to.value) {
						const object = JSON.parse(to.value);

						this.selectedFaceCard = object.redemptionComponent;
						this.selectedItem = object.vItem;
						this.populateVoucherCode();
					} else {
						alert("Action not configured");
						this.selectedFaceCard = null;
						this.selectedItem = null;
					}
				}
			}
		},
		getClaimedItems() {
			setTimeout(() => {
				this.configureLoyalty();
				this.configureShareReward();
			}, 10);
		},
	},
	methods: {
		async configureLoyalty() {
			//get the loyalty vats

			this.loyaltyVats = this.getClaimedItems.filter(
				(e) => e.type == "loyalty" && e.itemStatus == "claimed"
			);

			if (this.loyaltyVats.length > 0) {
				for (let i = 0; i < this.loyaltyVats.length; i++) {
					const loyaltyVAT = this.loyaltyVats[i];
					const loyaltyConfig = loyaltyVAT.categoryConfig;
					if (loyaltyConfig.complete) {
						await vItemStore().allocatePrizeByVatId(loyaltyVAT!.id!);
						return;
					}

					const total = loyaltyConfig.total;
					const collected = loyaltyConfig.collected;
					let collectedList = this.formatListWithCommas(
						loyaltyConfig.collectedList
					);
					let unCollectedList = this.formatListWithCommas(
						loyaltyConfig.unCollectedList
					);
					const category = this.getCategoryByID(loyaltyVAT.categoryID);
					const vatName = category!.name || "";
					const loyaltyPreloader = document.getElementById(
						`${vatName}-preloader`
					);
					const loyaltyVatText = document.getElementById(`${vatName}-vat-text`);
					const loyaltyVatImage = document.getElementById(
						`${vatName}-vat-image`
					);
					const loyaltyPopupText =
						document.getElementById(`loyalty-popup-text`);
					if (loyaltyPreloader) {
						loyaltyPreloader.style.width = `${
							(parseInt(collected) / parseInt(total)) * 100
						}%`;
					}
					if (loyaltyVatText) {
						loyaltyVatText.innerText = loyaltyConfig.initLoyaltyVATText
							.replace(/{total}/g, total.toString())
							.replace(/{collected}/g, collected.toString());
					}
					if (loyaltyPopupText) {
						loyaltyPopupText.innerText = loyaltyConfig.initLoyaltyFaceCardText
							.replace(/{total}/g, total)
							.replace(/{collected}/g, collected)
							.replace(/{collectedList}/g, collectedList)
							.replace(/{unCollectedList}/g, unCollectedList);
					}
					if (
						loyaltyVatImage &&
						loyaltyConfig.loyaltyVATImage &&
						loyaltyConfig.loyaltyVATImage != ""
					) {
						loyaltyVatImage.style.backgroundImage = `url(${loyaltyConfig.loyaltyVATImage})`;
						loyaltyVatImage.style.backgroundPosition = "center";
						loyaltyVatImage.style.backgroundSize = "cover";
					}
				}
			}
		},
		formatListWithCommas(collectedList: string[]) {
			if (collectedList.length === 0) {
				return "";
			} else if (collectedList.length === 1) {
				return collectedList[0];
			} else if (collectedList.length === 2) {
				return collectedList.join(" and ");
			} else {
				var lastItem = collectedList.pop();
				var formattedList = collectedList.join(", ") + ", and " + lastItem;
				return formattedList;
			}
		},
		configureShareReward() {
			const shareButtons =
				document.querySelectorAll<HTMLElement>(".share-reward");
			const shareText = document.querySelectorAll<HTMLElement>(".share-text");
			for (let i = 0; i < shareButtons.length; i++) {
				//get closest parent element with id attribute
				const parent = shareButtons[i].closest("[id]");
				if (parent && parent.id) {
					//Get the vat associated with the share button
					const vat = this.getClaimedItems.find((e) => e.id == parent.id);
					function clickEvent(e: Event) {
						//select the vat
						vItemStore().selectItem(parent!.id);
						e.stopPropagation();
					}
					shareButtons[i].removeEventListener("click", clickEvent);
					shareButtons[i].addEventListener("click", clickEvent);
					if (vat?.shareStatus == "shared" || vat?.shareStatus == "pending") {
						//remove the share button
						shareButtons[i].style.display = "none";
					} else {
						shareButtons[i].style.display = "block";
					}
				}
			}
			for (let i = 0; i < shareText.length; i++) {
				//get closest parent element with id attribute
				const parent = shareText[i].closest("[id]");
				if (parent && parent.id) {
					//Get the vat associated with the share button
					const vat = this.getClaimedItems.find((e) => e.id == parent.id);
					if (vat?.shareStatus == "shared") {
						shareText[i].style.display = "block";
					} else {
						shareText[i].style.display = "none";
					}
				}
			}
		},
		getPrizeByID(id: string) {
			return prizeStore().getPrizeByID(id);
		},
		getPoolByID(id: string) {
			return dropPoolStore().getPoolByID(id);
		},
		getCategoryByID(id: string) {
			return categoryStore().getItemByID(id);
		},
		allocatePrize() {
			if (this.selectedItem) {
				this.selectedItem.itemStatus = "allocated";
				navigationStore().saveShowFaceCard(false);
			}
		},
		clickForPrize(item: VItem) {
			const category = this.getCategoryByID(item.categoryID);
			if (category && category.gameMechanicType == "custom-game") {
				vItemStore().selectItem(item.id!);
				navigationStore().emitAction({
					type: "go-to-page",
					value: "game",
				});
				return;
			}

			if (item.type == "loyalty") {
				navigationStore().emitAction({
					type: "open-popup",
					value: "loyalty",
				});
				setTimeout(() => {
					this.configureLoyalty();
				}, 10);
			} else {
				this.selectedItem = item as VItem;
				const pool = this.getPoolByID(item.dropPoolID!);

				vItemStore().selectItem(this.selectedItem.id!);
				const prize = this.getPrizeByID(item.prizeID!);

				if (prize && prize.clickToPlay) {
					prizeStore().saveClickToPlay(prize.clickToPlay);
				} else {
					prizeStore().saveClickToPlay(false);
				}

				if (pool && pool.autoScalingPrizeStrategy && !item.winning) {
					prizeStore().saveIsWinning(false);
					this.selectedFaceCard = prize!.losingGameplayFaceCard as Container;
				} else {
					prizeStore().saveIsWinning(true);
					this.selectedFaceCard = prize!.gameplayFaceCard as Container;
				}

				navigationStore().saveShowFaceCard(true);
			}
		},
		async clickForRedemption(item: VItem) {
			if (item?.shareStatus == "pending") {
				navigationStore().emitAction({
					type: "open-popup",
					value: "reshare-cancel-share-reward",
				});
				return;
			}
			vItemStore().selectItem(item.id!);
			this.selectedItem = item as VItem;
			const prize = this.getPrizeByID(item.prizeID!);
			if (prize && prize.isWinningPrize) {
				this.selectedFaceCard = prize.redemptionFaceCard as Container;
				navigationStore().saveShowFaceCard(true);
				this.populateVoucherCode();
			} else {
				this.selectedFaceCard = prize!.redemptionFaceCard as Container;
				navigationStore().saveShowFaceCard(true);
				await vItemStore().setNoPrize();
				await vItemStore().getItems();
			}
		},
		async customEvent($event: any) {
			if ($event == "redeem" && this.selectedItem) {
				this.selectedItem.itemStatus = "allocated";
				this.clickForRedemption(this.selectedItem);
			} else if ($event == "losingPrize" && this.selectedItem) {
				await vItemStore().getItems();
				navigationStore().emitAction({
					type: "go-to-page",
					value: "vault",
				});
				navigationStore().saveShowFaceCard(false);
			}
		},
		showRedeemedPopup(item: VItem) {
			vItemStore().selectItem(item.id!);
			this.selectedItem = item as VItem;
			const prize = this.getPrizeByID(item.prizeID!);
			if (prize && prize.redemptionType === "scan-qr-code") {
				navigationStore().emitAction({
					type: "open-popup",
					value: "already-redeemed-qr",
				});
			} else if (prize && prize.redemptionType === "redeem-momint-nft") {
				navigationStore().emitAction({
					type: "open-popup",
					value: "already-redeemed-momint-nft",
				});
			} else if (prize && prize.redemptionType === "tap-to-redeem") {
				navigationStore().emitAction({
					type: "open-popup",
					value: "already-redeemed-tap-to-redeem",
				});
			} else if (prize && prize.redemptionType === "voucher") {
				navigationStore().emitAction({
					type: "open-popup",
					value: "already-redeemed-voucher",
				});
			} else if (prize && prize.redemptionType === "url-voucher") {
				navigationStore().emitAction({
					type: "open-popup",
					value: "already-redeemed-url-voucher",
				});
			} else if (prize && prize.redemptionType === "yoyo-coupon") {
				navigationStore().emitAction({
					type: "open-popup",
					value: "already-redeemed-yoyo-coupon",
				});
			}
		},

		populateVoucherCode() {
			setTimeout(() => {
				const voucherCodes = document.querySelectorAll(".voucher-code") as any;
				const voucherCode = document.querySelector(
					"#voucher-code"
				) as HTMLElement;
				if (voucherCode) {
					voucherCode.innerText = this.selectedItem!.voucherCode!;
				}
				if (voucherCodes) {
					for (let i = 0; i < voucherCodes.length; i++) {
						voucherCodes[i].innerText = this.selectedItem!.voucherCode!;
					}
				}
			}, 100);
		},
		getCategoryContainer(categoryID: string, type: string): Container {
			const emptyContainer = emptyContainerWithMessage(
				"Error",
				"Category missing"
			);
			const category = this.getCategoryByID(categoryID);
			if (category) {
				switch (type) {
					case "claimed":
						return category.unAllocatedComponent
							? category.unAllocatedComponent
							: emptyContainer;
				}
			}
			return emptyContainer;
		},
		getPrizeContainer(prizeID: string, type: string): Container {
			const emptyContainer = emptyContainerWithMessage(
				"Error",
				"Prize missing"
			);
			const prize = this.getPrizeByID(prizeID);
			if (prize) {
				switch (type) {
					case "redeem":
						return prize.redeemedComponent
							? prize.redeemedComponent
							: emptyContainer;
					case "allocated":
						return prize.allocatedComponent
							? prize.allocatedComponent
							: emptyContainer;
					default:
						return emptyContainer;
				}
			}
			return emptyContainer;
		},
	},
});
</script>

<style scoped lang="scss">
.scroller {
	position: relative;
	padding: 10px 0;
}
.item-cell {
	display: inline-block;
	width: 50%;
	overflow: hidden;
	.item-center {
		display: flex;
		width: 100%;
		justify-content: center;
		align-items: center;
		padding: 10px;
	}
}
.facecard-popup {
	position: fixed;
	display: flex;
	width: 100%;
	height: 100%;
	z-index: 100;
	top: 0;
	left: 0;
	background-color: #fff;
	justify-content: center;
	align-items: center;
	.close-btn {
		position: absolute;
		top: 20px;
		right: 20px;
		width: 30px;
		height: 30px;
		background: none;
		background-color: #fff;
		border-radius: 20px;
		font-size: 20px;
		line-height: 20px;
	}
}
.grey-out {
	opacity: 0.7;
}
</style>

import * as sentry from "@sentry/browser"

export class CustomLogger {
    private originalConsole: Console

    constructor() {
        this.originalConsole = console as Console
        // @ts-ignore
        console = this
    }

    debug(message?: any, ...optionalParams: any[]): void {
        this.log(message, ...optionalParams)
    }

    log(message?: any, ...optionalParams: any[]): void {
        if (
            import.meta.env.VITE_APP_STAGE === "dev" ||
            import.meta.env.VITE_APP_STAGE === "staging" ||
            import.meta.env.VITE_APP_STAGE === "local"
        ) {
            if (typeof message === "object") {
                this.originalConsole.log(message, ...optionalParams)
            } else {
                this.originalConsole.log(`LOG: ${message}`, ...optionalParams)
            }
        }
    }

    info(message?: any, ...optionalParams: any[]): void {
        if (typeof message === "object") {
            this.originalConsole.info("INFO: ", message, ...optionalParams)
        } else {
            this.originalConsole.info(`INFO: ${message}`, ...optionalParams)
        }
    }

    warn(message?: any, ...optionalParams: any[]): void {
        this.originalConsole.warn(`WARNING: ${message}`, ...optionalParams)
    }

    error(message?: any, ...optionalParams: any[]): void {
        if (message instanceof Error) {
            try {
                sentry.captureException(message)
            } catch (error) {
                this.originalConsole.error(
                    "ERROR: ",
                    JSON.stringify(message),
                    ...optionalParams
                )
            }
        }
        this.originalConsole.error(`ERROR: ${JSON.stringify(message)}`, ...optionalParams)
    }
}

import {
	cancelShareReward,
	generateShare,
	generateShareReward,
} from "@/api/share";
import router from "@/router";
import { Action, Container } from "@/types/customPage";
import { VItem } from "@/types/vItem";
import { validateEmail } from "@/utils/validateEmail";
import { defineStore } from "pinia";
import { authStore } from "./authStore";
import { campaignStore } from "./campaignStore";
import { vItemStore } from "./vItemStore";
import { customComponentStore } from "./customComponentStore";
import { prizeStore } from "./prizeStore";
import { categoryStore } from "./categoryStore";

interface INavigationStoreState {
	action: Action;
	currentAction: Action;
	previousAction: Action;
	popupVisible: boolean;
	selectedPopup: Container;
	inARView: boolean;
	showFaceCard: boolean;
	deniedPermissions: [];
	loading: boolean;
}

export const navigationStore = defineStore("navigationStore", {
	state: () =>
		({
			action: {},
			currentAction: {},
			previousAction: {},
			popupVisible: false,
			selectedPopup: {},
			inARView: false,
			showFaceCard: false,
			deniedPermissions: [],
			loading: false,
		} as INavigationStoreState),

	getters: {
		getAction(state: INavigationStoreState): Action {
			return state.action;
		},
		getCurrentAction(state: INavigationStoreState): Action {
			return state.currentAction;
		},
		getPreviousAction(state: INavigationStoreState): Action {
			return state.previousAction;
		},
		getPopupVisible(state: INavigationStoreState): boolean {
			return state.popupVisible;
		},
		getSelectedPopup(state: INavigationStoreState): Container {
			return state.selectedPopup;
		},
		getShowFaceCard(state: INavigationStoreState): boolean {
			return state.showFaceCard;
		},
		getLoading(state: INavigationStoreState): boolean {
			return state.loading;
		},
		getDeniedPermissions(state: INavigationStoreState): string[] {
			return state.deniedPermissions;
		},
	},
	actions: {
		async emitAction(action: Action) {
			if (action.type != "none") {
				this.popupVisible = false;
				switch (action.type) {
					case "go-to-page": {
						try {
							const campaignID = authStore().campaignID || "";
							await router.push(`/${campaignID}/${action.value}`);
						} catch (err) {
							console.error(err);
						}
						break;
					}
					case "go-to-url": {
						const urlAndTarget = action.value!.split(",");
						const url = urlAndTarget[0].split("url=")[1];
						const target = urlAndTarget[1].split("target=")[1];
						if (target == "_blank") {
							window.open("//" + url, "_blank");
						} else {
							window.location.replace("//" + url);
						}
						break;
					}

					case "share-link": {
						try {
							let dropPoolID: string | null = null;
							if (action.value) {
								dropPoolID = action.value;
							}
							const campaignID = authStore().campaignID || "";
							const userID = authStore().userID || "";
							const url = await generateShare(campaignID, userID, dropPoolID);

							const data = {
								url:
									url ||
									`${window.location.origin}/${
										window.location.pathname.split("/")[1]
									}`,
							};

							if (navigator.canShare && navigator.canShare(data)) {
								await navigator.share(data);
								// if (url) {
								// 	await navigator.share({
								// 		url: url,
								// 	});
								// } else {
								// 	// fallback url
								// 	await navigator.share({
								// 		url: `${window.location.origin}/${
								// 			window.location.pathname.split("/")[1]
								// 		}`,
								// 	});
								// }
								await campaignStore().shareCampaign();
							} else {
								alert("browser doesn't support share");
							}
						} catch (error) {
							console.error(error);
						}
						break;
					}
					case "share-reward": {
						try {
							const vatID = vItemStore().getSelectedItem!.id || null;
							const vat = vItemStore().getSelectedItem;

							if (!vatID && !vat) {
								return;
							}
							const category = categoryStore().getItemByID(vat?.categoryID!);

							if (!category) {
								return;
							}

							const campaignID = authStore().campaignID || "";
							const userID = authStore().userID || "";

							const shareID = await generateShareReward(
								campaignID,
								userID,
								vatID
							);

							console.log(`?shareRewardCode=${shareID}`);

							const data = {
								url: `${window.location.origin}/${
									window.location.pathname.split("/")[1]
								}?shareRewardCode=${shareID}`,
								text: category?.sharePrizeForRewardText || "",
							};
							console.log(data);
							if (navigator.canShare && navigator.canShare(data)) {
								await navigator.share(data);
							} else {
								alert("browser doesn't support share");
							}
						} catch (error) {
							console.error(error);
						}
						break;
					}
					case "cancel-share-reward": {
						try {
							const vat = vItemStore().getSelectedItem || null;
							if (!vat) {
								return;
							}
							const campaignID = authStore().campaignID || "";
							await cancelShareReward(campaignID, vat.shareID!);
						} catch (error) {
							console.error(error);
						}
						break;
					}
					case "reshare-reward": {
						const vat = vItemStore().getSelectedItem || null;
						if (!vat) {
							return;
						}
						const data = {
							url: `${window.location.origin}/${
								window.location.pathname.split("/")[1]
							}?shareRewardCode=${vat.shareID}`,
						};
						console.log(data);
						if (navigator.canShare && navigator.canShare(data)) {
							await navigator.share(data);
						} else {
							alert("browser doesn't support share");
						}
						break;
					}
					case "show-facecard": {
						try {
							const campaignID = authStore().campaignID || "";
							await router.push(`/${campaignID}/vault`);
						} catch (err) {
							console.error(err);
						}
						break;
					}

					case "log-out": {
						authStore().logout();
						break;
					}

					case "resend-pin": {
						const email = authStore().email;
						await authStore().signinLoginCode({ email: email });
						break;
					}

					case "open-popup": {
						const item = vItemStore().getSelectedItem;
						const component = customComponentStore().getCustomComponentByName(
							action.value!
						);
						if (component) {
							this.selectedPopup = component.container;
							this.popupVisible = true;
							if (item) {
								populateVoucherCode(item);
							}
						} else {
							alert(`Popup does not exist for ${action.value}`);
						}
						break;
					}

					case "close-popup": {
						this.popupVisible = false;
						break;
					}

					case "redeem-item": {
						this.setloading(true);
						const item = vItemStore().getSelectedItem;
						const prize = prizeStore().getPrizeByID(item!.prizeID!);
						const response = await vItemStore().redeemItem(
							`${item!.id}_${prize!.redemptionWalletID}`
						);

						if (response && response.success) {
							await vItemStore().getItems();
							if (prize!.redemptionType === "voucher") {
								await this.emitAction({
									type: "open-popup",
									value: "redeem-voucher-success",
								});
							} else {
								await this.emitAction({
									type: "open-popup",
									value: "tap-to-redeem-success",
								});
							}
						}
						this.setloading(false);
						break;
					}
					case "redeem-momint-nft": {
						const item = vItemStore().getSelectedItem;

						const prize = prizeStore().getPrizeByID(item!.prizeID!);
						this.setloading(true);
						const response = await vItemStore().redeemMomintNFT({
							merchantWalletID: prize!.redemptionWalletID,
							itemID: item!.id || "",
						});

						this.setloading(false);

						if (response && response.success) {
							await vItemStore().getItems();
							this.showFaceCard = false;
							this.emitAction({
								type: "open-popup",
								value: "redeem-momint-nft-success",
							});
						}
						break;
					}

					case "redeem-url-item": {
						const item = vItemStore().getSelectedItem;
						const prize = prizeStore().getPrizeByID(item!.prizeID!);
						await vItemStore().redeemItem(
							`${item!.id}_${prize!.redemptionWalletID}`
						);

						await vItemStore().getItems();
						this.saveShowFaceCard(false);
						const urlAndTarget = action.value!.split(",");
						let url = urlAndTarget[0].split("url=")[1];
						const target = urlAndTarget[1].split("target=")[1];
						url = url.replace("{voucher-code}", item!.voucherCode || "");
						if (target == "_blank") {
							window.open("//" + url, "_blank");
						} else {
							window.location.replace("//" + url);
						}
						break;
					}

					case "signup": {
						const emailInput =
							document.querySelector<HTMLInputElement>("#email");
						const signupButton =
							document.querySelector<HTMLInputElement>("#sign-up");
						const acceptTerms =
							document.querySelector<HTMLInputElement>("#accept-terms");

						const firstName =
							document.querySelector<HTMLInputElement>("#first-name");
						const lastName =
							document.querySelector<HTMLInputElement>("#last-name");
						const mobileNumber =
							document.querySelector<HTMLInputElement>("#mobile-number");
						const brandPreference =
							document.querySelector<HTMLInputElement>("#brand-preference");
						const secondaryCheckbox = document.querySelector<HTMLInputElement>(
							"#secondary-checkbox"
						);
						const emailOptInCheckbox =
							document.querySelector<HTMLInputElement>("#email-opt-in");
						const year = document.querySelector<HTMLSelectElement>("#dob-year");
						const month =
							document.querySelector<HTMLSelectElement>("#dob-month");
						const day = document.querySelector<HTMLSelectElement>("#dob-day");

						const dob: { [key: string]: string | null } = {
							year: null,
							month: null,
							day: null,
						};

						const regObject: any = {};
						if (emailInput && emailInput?.value.length == 0) {
							alert("Please add an email address");
							return;
						} else if (emailInput) {
							regObject["email"] = emailInput.value;
						}

						if (firstName && firstName?.value.length == 0) {
							alert("Please input your first name");
							return;
						} else if (firstName) {
							regObject["firstName"] = firstName.value;
						} else {
							regObject["firstName"] = "";
						}
						if (lastName && lastName?.value.length == 0) {
							alert("Please input your last name");
							return;
						} else if (lastName) {
							regObject["lastName"] = lastName.value;
						} else {
							regObject["lastName"] = "";
						}
						if (mobileNumber && mobileNumber?.value.length == 0) {
							alert("Please input your mobile number");
							return;
						} else if (mobileNumber) {
							regObject["mobileNumber"] = mobileNumber.value;
						} else {
							regObject["mobileNumber"] = "";
						}
						if (brandPreference && brandPreference?.value.length == 0) {
							alert("Please input your brand preference");
							return;
						} else if (brandPreference) {
							regObject["brandPreference"] = brandPreference.value;
						} else {
							regObject["brandPreference"] = "";
						}
						if (secondaryCheckbox) {
							regObject["secondaryCheckbox"] = secondaryCheckbox.checked;
						} else {
							regObject["secondaryCheckbox"] = false;
						}
						if (emailOptInCheckbox) {
							regObject["emailOptIn"] = emailOptInCheckbox.checked;
						} else {
							regObject["emailOptIn"] = false;
						}
						if (acceptTerms && !acceptTerms?.checked) {
							alert("Please accept the terms and conditions");
							return;
						} else if (acceptTerms) {
							regObject["termsAndConditionsCheck"] = acceptTerms.checked;
						} else {
							regObject["termsAndConditionsCheck"] = true;
						}
						//Year
						if (year && year?.value == "Year") {
							alert("Please input your year of birth");
							return;
						} else if (year) {
							dob.year = year.value;
						}
						//Month
						if (month && month?.value == "Month") {
							alert("Please input your month of birth");
							return;
						} else if (month) {
							dob.month = month.value;
						}
						//Day
						if (day && day?.value == "Day") {
							alert("Please input your day of birth");
							return;
						} else if (day) {
							dob.day = day.value;
						}

						const email = emailInput?.value.trim();

						if (email && validateEmail(email)) {
							signupButton?.setAttribute("disabled", "disabled");
							if (dob.year && dob.month && dob.day) {
								regObject[
									"dateOfBirth"
								] = `${dob.day}-${dob.month}-${dob.year}`;
								regObject["yearOfBirth"] = `${dob.year}`;
							} else {
								regObject["dateOfBirth"] = "";
								regObject["yearOfBirth"] = "";
							}
							console.log(regObject);

							const originText = signupButton?.innerText;
							signupButton!.innerText = "SIGNING IN...";
							const result = await authStore().registerLoginCode(regObject);

							if (signupButton && originText) {
								signupButton!.innerText = originText;
							}

							signupButton?.removeAttribute("disabled");
							if (result) {
								const campaignID = authStore().campaignID || "";
								router.push(`/${campaignID}/enter-pin`).catch(() => {
									//
								});
							}
						} else {
							alert("Please enter a valid email address.");
						}
						break;
					}

					case "signin-request-code": {
						const emailInput =
							document.querySelector<HTMLInputElement>("#email");
						const signInBtn =
							document.querySelector<HTMLInputElement>("#signin-btn");
						const acceptTerms =
							document.querySelector<HTMLInputElement>("#accept-terms");

						const regObject: any = {};
						if (emailInput && emailInput?.value.length == 0) {
							alert("Please add an email address");
							return;
						} else if (emailInput) {
							regObject["email"] = emailInput.value;
						}

						if (acceptTerms && !acceptTerms?.checked) {
							alert("Please accept the terms and conditions");
							return;
						}

						const email = emailInput?.value.trim();

						if (email && validateEmail(email)) {
							let originText = "";
							this.setloading(true);
							if (signInBtn) {
								signInBtn?.setAttribute("disabled", "disabled");
								originText = signInBtn?.innerText;
								signInBtn!.innerText = "SIGNING IN...";
							} else {
								alert(
									"signInBtn not found, make sure you have an element with the id 'signin-btn'"
								);
							}
							const result = await authStore().signinLoginCode(regObject);
							this.setloading(false);
							if (signInBtn && originText) {
								signInBtn.innerText = originText;
								signInBtn.removeAttribute("disabled");
							}
							if (result) {
								const campaignID = authStore().campaignID || "";
								router.push(`/${campaignID}/enter-pin`).catch(() => {
									//
								});
							}
						} else {
							alert("Please enter a valid email address.");
						}
						break;
					}

					case "submit-pin": {
						const pin = document.querySelector<HTMLInputElement>("#pin")!.value;

						const submitButton =
							document.querySelector<HTMLInputElement>("#submit-pin");
						const pinNumber = parseInt(pin);

						submitButton?.setAttribute("disabled", "disabled");
						const originText = submitButton?.innerText;
						submitButton!.innerText = "LOGGING IN...";
						this.setloading(true);
						const result = await authStore().login(pinNumber);
						this.setloading(false);
						if (submitButton && originText) {
							submitButton!.innerText = originText;
						}
						submitButton?.removeAttribute("disabled");
						if (result) {
							const campaign = campaignStore().getItem;
							let pageAfterAuth = campaign?.pageAfterAuth;
							if (!pageAfterAuth || pageAfterAuth == "") {
								pageAfterAuth = "vault";
							}
							authStore().savePageAfterAuth(true);
							const campaignID = authStore().campaignID || "";
							router.push(`/${campaignID}/${pageAfterAuth}`).catch(() => {
								//
							});
						} else {
							alert("Invalid pin, please try again");
							if (submitButton && originText) {
								submitButton!.innerText = originText;
								submitButton.removeAttribute("disabled");
								document.querySelector<HTMLInputElement>("#pin")!.value = "";
							}
						}
						break;
					}
					case "copy-to-clipboard": {
						const copyText = document.getElementById(action.value!) as any;
						copyToClipboard(copyText.innerText);
						break;
					}

					default:
						break;
				}

				this.action = action;
				this.previousAction = action;
				setTimeout(() => {
					this.action = { type: "none", value: "" };
				}, 10);
			}
		},
		saveAction(action: Action) {
			this.previousAction = action;
		},
		isInARView() {
			this.inARView = true;
		},
		saveShowFaceCard(value: boolean) {
			this.showFaceCard = value;
		},
		setloading(value: boolean) {
			this.loading = value;
		},
		saveDeniedPermissions(deniedPermission: never) {
			if (!this.deniedPermissions.includes(deniedPermission)) {
				this.deniedPermissions.push(deniedPermission);
			}
		},
	},
});

function populateVoucherCode(selectedItem: VItem) {
	setTimeout(() => {
		const voucherCodes = document.querySelectorAll(".voucher-code") as any;
		const voucherCode = document.querySelector("#voucher-code") as HTMLElement;
		if (voucherCode) {
			voucherCode.innerText = selectedItem!.voucherCode!;
		}
		if (voucherCodes) {
			for (let i = 0; i < voucherCodes.length; i++) {
				voucherCodes[i].innerText = selectedItem!.voucherCode!;
			}
		}
	}, 100);
}
function copyToClipboard(text: string) {
	navigator.clipboard
		.writeText(text)
		.then(() => {
			alert("Successfully copied to clipboard");
		})
		.catch((err) => {
			console.error("Unable to copy text to clipboard", err);
		});
}
